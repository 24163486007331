import React from 'react';

import axios from 'axios';

import Header from './components/header';

import Events from './components/events';

import Launch from './components/launch';

import Release from './components/release';

import Instagram from './components/instagram';

import Footer from './components/footer';

export default class pageIndex extends React.Component{

	state = {
		page: {},
		events: {},
		description: '',
		phoneDdd: '',
		phone: '',
		cellPhoneDdd: '',
		cellPhone: ''
	}

	async componentDidMount(){

		const page = await axios.get('http://painel.icomp.com.br/wp-json/wp/v2/institucional/281');

		const events = await axios.get('http://painel.icomp.com.br/wp-json/icomp/events?user=9');

		const 	phone_split = page.data.acf.phone.split(' ', -1),
				cell_phone_split = page.data.acf.cell_phone.split(' ', -1);

		await this.setState({
			page: page.data.acf,
			events: events.data.items,
			description: page.data.acf.sections_biography[0].description,
			phoneDdd: phone_split[0],
			phone: phone_split[1],
			cellPhoneDdd: cell_phone_split[0],
			cellPhone: cell_phone_split[1]
		});

	}

	render(){

		return(

			<React.Fragment>

				<Header {... this.state.page} />

				<main id="main">

					{this.state.events &&(

						<Events {... this.state.events} />

					)}

					<Launch {... this.state.page} events={this.state.events} />

					<Release description={this.state.description} />

					<Instagram user={this.state.page.instagram} />

				</main>

				<Footer {... this.state.page} phoneDdd={this.state.phoneDdd} phone={this.state.phone}
				cellPhoneDdd={this.state.cellPhoneDdd} cellPhone={this.state.cellPhone} />

			</React.Fragment>

		);

	}

}