import React from 'react';

import {Header, FixedMenu} from '../style';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faFacebook, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';

import ReactWOW from 'react-wow';

export default class componentHeader extends React.Component{

	state = {
		classMenu: ''
	};

	componentDidMount(){

		window.onscroll = function(){

			if(window.pageYOffset > 250){

				document.querySelector('div.fixed-menu').classList.add('active');

			}else{

				document.querySelector('div.fixed-menu').classList.remove('active');

			}

		}

	}

	handleScroll = (e) => {

		e.preventDefault();

		window.scrollTo({
			top: document.querySelector(e.target.getAttribute('data-scroll')).offsetTop - 107,
			behavior: 'smooth'
		});

	}

	render(){

		return(

			<React.Fragment>

				<FixedMenu className="fixed-menu position-fixed z-index-20 left-0 w-100 pt-4 pr-3 pr-xl-5 pb-4 pl-3 pl-xl-5 
				d-none d-md-flex justify-content-between align-items-center transition">

					<img src={require('~/assets/img/diogo-silva-menu.png')} alt="Diogo Silva" title="Diogo Silva" 
					width="158" height="59" className="img-fluid d-none d-lg-inline-block" />

					{/* <button className="button-open-menu border-0 bg-transparent pr-0 pl-0" onClick={this.openMenu}>

						<img src={require('~/assets/img/icons/menu.png')} alt="" title="" width="30" height="30" />

					</button> */}

					<ul className={`pl-0 ml-0 mb-0 d-flex justify-content-center align-items-center flex-column flex-md-row transition${this.state.classMenu}`}>

						<li className="BrandonGrotesqueBlack font-20 text-uppercase mt-3 mb-3 mt-md-0 mb-md-0 mr-md-3 mr-xl-5">

							<a href="http://www.diogosilvaoficial.com.br" title="Acessar" className="text-dark text-none" 
							onClick={this.handleScroll} data-scroll="header">

								Capa

							</a>

						</li>

						<li className="BrandonGrotesqueBlack font-20 text-uppercase mt-3 mb-3 mt-md-0 mb-md-0 mr-md-3 mr-xl-5">

							<a href="http://www.diogosilvaoficial.com.br" title="Acessar" className="text-dark text-none" 
							onClick={this.handleScroll} data-scroll="section.events">

								Agenda

							</a>

						</li>

						<li className="BrandonGrotesqueBlack font-20 text-uppercase mt-3 mb-3 mt-md-0 mb-md-0 mr-md-3 mr-xl-5">

							<a href="http://www.diogosilvaoficial.com.br" title="Acessar" className="text-dark text-none" 
							onClick={this.handleScroll} data-scroll="section.launch">

								Lançamento

							</a>

						</li>

						<li className="BrandonGrotesqueBlack font-20 text-uppercase mt-3 mb-3 mt-md-0 mb-md-0 mr-md-3 mr-xl-5">

							<a href="http://www.diogosilvaoficial.com.br" title="Acessar" className="text-dark text-none" 
							onClick={this.handleScroll} data-scroll="section.release">

								Release

							</a>

						</li>

						<li className="BrandonGrotesqueBlack font-20 text-uppercase mt-3 mb-3 mt-md-0 mb-md-0 mr-md-3 mr-xl-5">

							<a href="http://www.diogosilvaoficial.com.br" title="Acessar" className="text-dark text-none" 
							onClick={this.handleScroll} data-scroll="section.instagram">

								Fotos

							</a>

						</li>

						<li className="BrandonGrotesqueBlack font-20 text-uppercase mt-3 mb-3 mt-md-0 mb-md-0">

							<a href="http://www.diogosilvaoficial.com.br" title="Acessar" className="text-dark text-none" 
							onClick={this.handleScroll} data-scroll="footer">

								Contato

							</a>

						</li>

					</ul>


					<div className="d-flex justify-content-center align-items-center position-relative z-index-2">

						<a href={`http://www.facebook.com/${this.props.facebook}`} title="Curta no Facebook" 
						target="_blank" rel="noopener noreferrer" className="font-30 text-dark text-none">

							<FontAwesomeIcon icon={faFacebook} />

						</a>

						<a href={`http://www.instagram.com/${this.props.instagram}`} title="Siga no Instagram" 
						target="_blank" rel="noopener noreferrer" className="font-30 text-dark text-none mr-3 ml-3 mr-xl-4 ml-xl-4">

							<FontAwesomeIcon icon={faInstagram} />
						
						</a>

						<a href={this.props.youtube} title="Assista no YouTube" 
						target="_blank" rel="noopener noreferrer" className="fab fa-youtube font-30 text-dark text-none">

							<FontAwesomeIcon icon={faYoutube} />

						</a>

					</div>

				</FixedMenu>


				<Header id="header" className="position-relative">

					<h1 className="sr-only">

						Diogo Silva

					</h1>

					<div className="container-fluid position-absolute top-0 left-0 w-100 h-100 p-b-80">

						<div className="container h-100 d-flex justify-content-end align-items-center flex-column">

							<ReactWOW animation="fadeInUp">

								<img src={require('~/assets/img/diogo-silva.png')} alt="Diogo Silva" 
								title="Diogo Silva" width="642" height="267" className="img-fluid mb-3" />

							</ReactWOW>

							<div className="d-flex justify-content-center align-items-center pb-4">

								<a href={`http://www.facebook.com/${this.props.facebook}`} title="Curta no Facebook" 
								target="_blank" rel="noopener noreferrer" className="font-40 text-light text-none">

									<FontAwesomeIcon icon={faFacebook} />

								</a>

								<a href={`http://www.instagram.com/${this.props.instagram}`} title="Siga no Instagram" 
								target="_blank" rel="noopener noreferrer" className="font-40 text-light text-none mr-4 ml-4">

									<FontAwesomeIcon icon={faInstagram} />

								</a>

								<a href={this.props.youtube} title="Assista no YouTube" 
								target="_blank" rel="noopener noreferrer" className="font-40 text-light text-none">

									<FontAwesomeIcon icon={faYoutube} />

								</a>

							</div>

						</div>

					</div>

				</Header>

			</React.Fragment>

		)

	}

};