import styled, {css} from 'styled-components';

export const colors = {
	light: '#FFFFFF',

	dark: '#474747',
	dark_hover: '#474747',

	primary: '#115D9D',
	primary_hover: '#115D9D',

	secondary: '#FFC660',
	secondary_hover: '#FFC660'
};

function createCSS() {

	let styles = '';

	// Letter
	for (let i = 1; i < 11; i += 1) {

		styles += `
			.letter-${i}{
				letter-spacing: ${i}px;
			}
		`;

	}

	let classes = {
		'font-size': 'font-', 'z-index': 'z-index-', 'line-height': 'lh-',
	};

	Object.size = function (obj) {

		let size = 0, key;

		for (key in obj) {

			if (obj.hasOwnProperty(key)) size++;

		}

		return size;

	};

	var key = Object.keys(classes);

	var value = Object.values(classes);

	for (let i = 0; i < Object.size(classes); i++) {

		for (let j = 0; j < 101; j += 1) {

			let split = key[i].split('-');

			styles += `
				.${value[i] + j}{
					${key[i].replace('-negative', '')}: ${(split[split.length - 1] === 'negative' ? '-' + j : j) + (key[i] !== 'z-index' ? 'px' : '')};
				}
			`;

		}

	}

	return css`${styles}`;

}

export const Fragment = styled.div`
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	overflow-x: hidden;

	@font-face{
		font-family: 'BrandonGrotesqueBlack';
		src: url(${require('~/assets/fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlack.woff')}) format('woff'),
			url('~/assets/fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlack.woff2') format('woff2'),
			url('~/assets/fonts/BrandonGrotesqueBlack/BrandonGrotesqueBlack.svg#BrandonGrotesqueBlack') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	@font-face{
		font-family: 'BrandonGrotesqueRegular';
		src: url(${require('~/assets/fonts/BrandonGrotesqueRegular/BrandonGrotesqueRegular.woff')}) format('woff'),
			url('~/assets/fonts/BrandonGrotesqueRegular/BrandonGrotesqueRegular.woff2') format('woff2'),
			url('~/assets/fonts/BrandonGrotesqueRegular/BrandonGrotesqueRegular.svg#BrandonGrotesqueRegular') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	@font-face{
		font-family: 'GothamBlack';
		src: url(${require('~/assets/fonts/GothamBlack/GothamBlack.woff')}) format('woff'),
			url('~/assets/fonts/GothamBlack/GothamBlack.woff2') format('woff2'),
			url('~/assets/fonts/GothamBlack/GothamBlack.svg#GothamBlack') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	@font-face{
		font-family: 'RobinsonPress';
		src: url(${require('~/assets/fonts/RobinsonPress/RobinsonPress.woff')}) format('woff'),
			url('~/assets/fonts/RobinsonPress/RobinsonPress.woff2') format('woff2'),
			url('~/assets/fonts/RobinsonPress/RobinsonPress.svg#RobinsonPress') format('svg');
		font-weight: normal;
		font-style: normal;
		font-display: auto;
	}
	.BrandonGrotesqueBlack{
		font-family: "BrandonGrotesqueBlack";
	}
	.BrandonGrotesqueRegular{
		font-family: "BrandonGrotesqueRegular";
	}
	.GothamBlack{
		font-family: "GothamBlack";
	}
	.RobinsonPress{
		font-family: "RobinsonPress";
	}

	a{
		text-decoration: none !important;
		&.indent{
			text-indent: -9999px;
		}
	}

	.carousel .slide{
		background: transparent !important;
		img{
			width: auto;
		}
	}

	.carousel.carousel-slider{
		overflow: visible !important;
		ul.control-dots{
			bottom: -3rem;
			padding-left: 0px;
			li{
				width: 13px;
				height: 13px;
				border-radius: 50%;
				box-shadow: none;
				border: 1px solid ${colors.primary};
				outline: none;
				background: transparent;
				&.selected{
					background: ${colors.primary};
				}
			}

			@media (max-width: 992px){
				bottom: -2.5rem;
			}
		}
	}

	.transition{
		transition: 0.5s;
	}

	.wow{
		visibility: hidden;
	}

	.text-dark{
		color: #333 !important;
	}
	.bg-dark{
		background: #333 !important;
	}

	@keyframes floating{
		from{
			transform: translate(0,0px);
		}
		65%{
			transform:translate(0,15px);
		}
		to{
			transform:translate(0,0px);
		}
	}
	.floating{
		animation-name: floating;
		animation-duration: 3s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}

	div.bg-loader-site{
		z-index: 9999;
		top: 0px;
		left: 0px;
		background-color: rgba(0,0,0,0.6);
		div.loader-site{
			width: 50px;
			svg.circular{
				height: 50px;
				animation: rotate 2s linear infinite;
				transform-origin: center center;
				.path{
					stroke-dasharray: 1, 200;
					stroke-dashoffset: 0;
					animation: dash 1.5s ease-in-out infinite, color 3s ease-in-out infinite;
					stroke-linecap: round;
				}
			}
		}
		&.hidden{
			opacity: 0;
			pointer-events: none;
		}
	}
	@keyframes rotate{
		100%{
			transform: rotate(360deg);
		}
	}
	@keyframes dash{
		0%{
			stroke-dasharray: 1, 200;
			stroke-dashoffset: 0;
		}
		50%{
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -35px;
		}
		100%{
			stroke-dasharray: 89, 200;
			stroke-dashoffset: -124px;
		}
	}
	@keyframes color{
		100%, 0%{
			stroke: #EDB665;
		}
		70%{
			stroke: #DD555A;
		}
	}

	.left-0{
		left: 0px;
	}
	.font-normal{
		font-weight: normal;
	}
	.ov-hidden{
		overflow: hidden;
	}


	/* Colors */
	.text-light{
		color: ${colors.light} !important;
	}
	.text-dark{
		color: ${colors.dark} !important;
	}
	a.text-dark:hover{
		color: ${colors.dark_hover} !important;
	}
	.bg-dark{
		background: ${colors.dark} !important;
	}
	a.bg-dark:hover{
		background: ${colors.dark_hover} !important;
	}
	.text-primary{
		color: ${colors.primary} !important;
	}
	a.text-primary:hover{
		color: ${colors.primary_hover} !important;
	}
	.bg-primary{
		background: ${colors.primary} !important;
	}
	a.bg-primary:hover{
		background: ${colors.primary_hover} !important;
	}
	.text-secondary{
		color: ${colors.secondary} !important;
	}
	a.text-secondary:hover{
		color: ${colors.secondary_hover} !important;
	}
	.bg-secondary{
		background: ${colors.secondary} !important;
	}
	a.bg-secondary:hover{
		background: ${colors.secondary_hover} !important;
	}

	${createCSS()};

`;