// import React from 'react';

// import {Routes} from 'react-static';

// import 'bootstrap/dist/css/bootstrap.min.css';

// import 'react-wow/example/assets/styles/animate.css';

// import {Fragment} from './styles/global';

// const App = () => (

// 	<Fragment className="container-fluid container-fluid position-relative pr-0 pl-0">

// 		<Routes />

// 	</Fragment>

// );

// export default App;


import React from 'react';

import Main from '~/pages/index/index';

import {Fragment} from '~/styles/global';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-wow/example/assets/styles/animate.css';

const App = () => (

	<Fragment className="container-fluid container-fluid position-relative pr-0 pl-0">

		<Main />

	</Fragment>

);

export default App;