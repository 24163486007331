import React from 'react';

import {Release} from '../style';

import ReactWOW from 'react-wow';

const componentRelease = props => (

	<Release className="release position-relative z-index-3 d-flex justify-content-between align-items-center pt-5">

		<ReactWOW animation="fadeInUp" duration="2s">

			<img src={require('~/assets/img/background/blue-leaf-2.png')} alt="" title="" 
			width="423" height="284" className="blue-flower-2 d-none d-lg-inline-block position-absolute top-0 left-0 img-fluid" />

		</ReactWOW>

		<ReactWOW animation="fadeInRight" duration="2s">

			<img src={require('~/assets/img/background/pink-flower.png')} alt="" title="" 
			width="243" height="303" className="pink-flower position-absolute top-0 right-0 img-fluid" />

		</ReactWOW>

		<img src={require('~/assets/img/background/blue-leaf.png')} alt="" title="" 
		width="111" height="133" className="blue-flower d-none d-md-inline-block position-absolute bottom--60 right-20 img-fluid floating" />

		<img src={require('~/assets/img/background/picture-diogo-release.png')} width="866" height="959"
		alt="Diogo Silva" title="Diogo Silva" className="picture-diogo-release img-fluid position-absolute bottom-0 left-0 pr-5 pl-5 d-none d-xl-inline-block" />

		<span></span>

		<div className="content-text h-75 d-flex justify-content-start align-items-start flex-column ov-hidden">

			<h2 className="RobinsonPress text-primary font-70 lh-70 text-uppercase font-normal d-flex justify-content-start align-items-start flex-column mb-4">

				<span className="mb-2">Release</span>

				<span className="border-title-release"></span>

			</h2>

			<div className="text w-100 BrandonGrotesqueRegular font-18 text-dark lh-40 pr-3">

				<div dangerouslySetInnerHTML={{__html: props.description}} />

			</div>

		</div>

	</Release>

);

export default componentRelease;