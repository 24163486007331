import React from 'react';

import {Launch} from '../style';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faFacebook, faTwitter, faWhatsapp} from '@fortawesome/free-brands-svg-icons';

import ReactWOW from 'react-wow';

const componentLaunch = props => (
	
	<Launch className="launch position-relative z-index-2" style={{marginTop: (props.events ? '-70' : '0') + 'px'}}>

		<div className="container d-flex justify-content-center align-items-center flex-column">

			<ReactWOW animation="fadeInUp" duration="2s">

				<h2 className="RobinsonPress font-70 lh-70 text-light text-uppercase font-normal text-center mb-5">

					{props.title_launch}

				</h2>

			</ReactWOW>


			<ReactWOW animation="fadeInUp" duration="2s">

				<div className="bg-video w-100 position-relative mb-5">

					<iframe title={props.title_launch} src={`https://www.youtube.com/embed/${props.video_id_launch}`} 
					className="position-absolute w-100 h-100 border-0"></iframe>

				</div>

			</ReactWOW>


			<div className="w-100 d-flex justify-content-center justify-content-md-between align-items-center flex-wrap flex-column flex-lg-row mb-5 pb-5">

				{(props.spotify || props.deezer || props.youtube_music) &&(

					<div className="d-flex justify-content-center align-items-center mb-4">

						<ReactWOW animation="fadeInLeft" duration="2s">

							<span className="BrandonGrotesqueBlack font-18 text-light text-uppercase mr-3">

								Ouça no

							</span>

						</ReactWOW>

						{props.spotify && (

							<ReactWOW animation="fadeInLeft" duration="2s" delay="0.1s">

								<a href={props.spotify} title="Ouça no Spotify" target="_blank" rel="noopener noreferrer" className="mr-3">

									<img src={require('~/assets/img/icons/spotify.png')} alt="" title="" width="35" height="35" />

								</a>

							</ReactWOW>

						)}

						{props.deezer && (

							<ReactWOW animation="fadeInLeft" duration="2s" delay="0.2s">

								<a href={props.deezer} title="Ouça no Deezer" target="_blank" rel="noopener noreferrer" className="mr-3">

									<img src={require('~/assets/img/icons/deezer.png')} alt="" title="" width="43" height="35" />

								</a>

							</ReactWOW>

						)}

						{props.youtube_music && (

							<ReactWOW animation="fadeInLeft" duration="2s" delay="0.3s">

								<a href={props.youtube_music} title="Ouça no YouTube Music" target="_blank" rel="noopener noreferrer" className="mr-3">

									<img src={require('~/assets/img/icons/youtube-music.png')} alt="" title="" width="35" height="35" />

								</a>

							</ReactWOW>

						)}

					</div>

				)}


				{(props.google_play || props.itunes) &&(

					<div className="d-flex justify-content-center align-items-center mb-4">

						<ReactWOW animation="fadeInLeft" duration="2s" delay="0.4s">

							<span className="BrandonGrotesqueBlack font-18 text-light text-uppercase mr-3">

								Compre no

							</span>

						</ReactWOW>

						{props.google_play && (

							<ReactWOW animation="fadeInLeft" duration="2s" delay="0.5s">

								<a href={props.google_play} title="Compre na Google Play" target="_blank" rel="noopener noreferrer" className="mr-3">

									<img src={require('~/assets/img/icons/google-play.png')} alt="" title="" width="35" height="35" />

								</a>

							</ReactWOW>

						)}

						{props.itunes && (

							<ReactWOW animation="fadeInLeft" duration="2s" delay="0.6s">

								<a href={props.itunes} title="Compre no iTunes" target="_blank" rel="noopener noreferrer" className="mr-3">

									<img src={require('~/assets/img/icons/itunes.png')} alt="" title="" width="35" height="35" />

								</a>

							</ReactWOW>

						)}

					</div>

				)}


				{props.youtube && (

					<div className="d-flex justify-content-center align-items-center mb-4">

						<ReactWOW animation="fadeInRight" duration="2s" delay="0.5s">

							<span className="BrandonGrotesqueBlack font-18 text-light text-uppercase mr-3">

								Assista no

							</span>

						</ReactWOW>

						<ReactWOW animation="fadeInRight" duration="2s" delay="0.4s">

							<a href={props.youtube} title="Assista no YouTube" target="_blank" rel="noopener noreferrer" className="mr-3">

								<img src={require('~/assets/img/icons/youtube.png')} alt="" title="" width="45" height="35" />

							</a>

						</ReactWOW>

					</div>

				)}


				<div className="d-flex justify-content-center align-items-center mb-4">

					<ReactWOW animation="fadeInRight" duration="2s" delay="0.3s">

						<span className="BrandonGrotesqueBlack font-18 text-light text-uppercase mr-3">

							Compartilhe

						</span>

					</ReactWOW>

					<ReactWOW animation="fadeInRight" duration="2s" delay="0.2s">

						<a href={`https://www.facebook.com/sharer/sharer.php?u=https://www.youtube.com/watch?v=${props.video_id_launch}`} 
						title="Compartilhe no Facebook" target="_blank" rel="noopener noreferrer" className="font-30 text-light mr-3 text-none">

							<FontAwesomeIcon icon={faFacebook} />

						</a>

					</ReactWOW>

					<ReactWOW animation="fadeInRight" duration="2s" delay="0.1s">

						<a href={`https://twitter.com/intent/tweet?text=https://www.youtube.com/watch?v=${props.video_id_launch}`} 
						title="Compartilhe no Twitter" target="_blank" rel="noopener noreferrer" className="font-30 text-light mr-3 text-none">

							<FontAwesomeIcon icon={faTwitter} />

						</a>

					</ReactWOW>

					<ReactWOW animation="fadeInRight" duration="2s">

						<a href={`https://api.whatsapp.com/send?text=https://youtu.be/${props.video_id_launch}`} 
						title="Compartilhe no WhatsApp" target="_blank" rel="noopener noreferrer" className="fab fa-whatsapp font-30 text-light text-none">

							<FontAwesomeIcon icon={faWhatsapp} />

						</a>

					</ReactWOW>

				</div>

			</div>


			<div className="w-100 d-flex justify-content-center align-items-center flex-column">

				<ReactWOW animation="fadeInUp" duration="2s">

					<strong className="RobinsonPress font-70 lh-70 text-light text-uppercase font-normal text-center mb-4 letter-3">

						Mais sucessos de Diogo Silva em

					</strong>

				</ReactWOW>

				<div className="d-flex justify-content-center align-items-center flex-wrap">

					<ReactWOW animation="fadeInUp" duration="2s" delay="0.1s">

						<a href="https://open.spotify.com/artist/77wUx2RShbI2nSedFf5GKv" title="Confira" target="_blank" rel="noopener noreferrer" className="mr-3 ml-3 mb-4">

							<img src={require('~/assets/img/icons/spotify-sucessos.png')} alt="" title="" width="137" height="41" />

						</a>

					</ReactWOW>

					<ReactWOW animation="fadeInUp" duration="2s" delay="0.2s">

						<a href="https://www.deezer.com/fr/artist/4759705" title="Confira" target="_blank" rel="noopener noreferrer" className="mr-3 ml-3 mb-4">

							<img src={require('~/assets/img/icons/deezer-sucessos.png')} alt="" title="" width="127" height="41" />

						</a>

					</ReactWOW>

					<ReactWOW animation="fadeInUp" duration="2s" delay="0.3s">

						<a href="https://music.youtube.com/channel/UCP6_Eczwu99cIxfadYmtBtw" title="Confira" target="_blank" rel="noopener noreferrer" className="mr-3 ml-3 mb-4">

							<img src={require('~/assets/img/icons/youtube-music-sucessos.png')} alt="" title="" width="125" height="41" />

						</a>

					</ReactWOW>

					<ReactWOW animation="fadeInUp" duration="2s" delay="0.4s">

						<a href="https://play.google.com/store/music/artist/Diogo_Silva?id=Av2wteotgzyl4vem7lh6aslkwse" title="Confira" target="_blank" rel="noopener noreferrer" className="mr-3 ml-3 mb-4">

							<img src={require('~/assets/img/icons/google-play-sucessos.png')} alt="" title="" width="145" height="41" />

						</a>

					</ReactWOW>

					<ReactWOW animation="fadeInUp" duration="2s" delay="0.5s">

						<a href="https://itunes.apple.com/bo/artist/diogo-silva/638876000" title="Confira" target="_blank" rel="noopener noreferrer" className="mr-3 ml-3 mb-4">

							<img src={require('~/assets/img/icons/itunes-sucessos.png')} alt="" title="" width="139" height="41" />

						</a>

					</ReactWOW>

				</div>

			</div>

		</div>

	</Launch>

);

export default componentLaunch;