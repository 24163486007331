import styled from 'styled-components';

import {colors} from '../../styles/global';

export const FixedMenu = styled.div`
	top: -300px;
	box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
	background: #FFE6A9;
	ul{
		list-style: none;
		@media (max-width: 768px){
			position: fixed;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100vh;
			opacity: 0;
			pointer-events: none;
			background: #FFE6A9;
		}
		&.active{
			opacity: 1;
			pointer-events: all;
		}
	}
	&.active{
		top: 0px;
	}
`;

export const Header = styled.header`
	height: 867px;
	background: url(${require('~/assets/img/background/bg-header.jpg')}) center top no-repeat;
	@media (max-width: 768px){
		height: 600px;
		background: url(${require('~/assets/img/background/bg-header-mobile.jpg')}) center top no-repeat;
		div.container-fluid{
			padding-bottom: 0px;
		}
	}
`;

export const Events = styled.section`
	height: 631px;
	background: url(${require('~/assets/img/background/bg-events.png')}) center top no-repeat;
	h2{
		font-size: 160px;
		line-height: 120px;
		span:last-child span:last-child{
			font-size: 140px;
			line-height: 107px;
		}
	}
	@media (max-width: 992px){
		h2, h2 span{
			font-size: 70px !important;
			line-height: 50px !important;
		}
	}
	div.content-carousel{
		max-width: 1000px;
	}
`;

export const Launch = styled.section`
	padding-top: 100px;
	padding-bottom: 400px;
	background: url(${require('~/assets/img/background/bg-launch.png')}) center top no-repeat;
	div.bg-video{
		padding-bottom: 52.25%;
		iframe{
			top: 0px;
			left: 0px;
		}
	}
	@media (max-width: 992px){
		padding-bottom: 200px;
	}
`;

export const Release = styled.section`
	@-webkit-keyframes slide{
		from{
			background-position: 0 0;
		}
		to{
			background-position: -400px 0;
		}
	}

	height: 754px;
	margin-top: -102px;
	background: url(${require('~/assets/img/background/bg-release.png')}) center top no-repeat;
	h2 span.border-title-release{
		width: 190px;
		height: 15px;
		animation: slide 20s linear infinite;
		background: url(${require('~/assets/img/icons/border-title-release.png')}) center top repeat;
	}
	img.picture-diogo-release{
		width: 45%;
		margin-left: 10%;
	}
	div.content-text{
		width: 30%;
		margin-right: 13%;
		div.text{
			overflow: auto;
			&::-webkit-scrollbar-track, &::-webkit-scrollbar{
				background-color: ${colors.dark};
			}
			&::-webkit-scrollbar{
				width: 5px;
			}
			&::-webkit-scrollbar-thumb{
				background: ${colors.primary};
			}
		}
		@media (max-width: 1200px){
			width: 90% !important;
			margin-right: 5% !important;
		}
	}
	img{
		&.pink-flower{
			width: 12.65%;
			@media (max-width: 992px){
				width: 15%;
			}
		}
		&.blue-flower{
			width: 6%;
		}
		&.blue-flower-2{
			width: 20%;
		}
	}

	.bottom--60{
		bottom: -60px;
	}
	.right-20{
		right: 20px;
	}
	.top-0{
		top: 0px;
	}
	.right-0{
		right: 0px;
	}
	.bottom-0{
		bottom: 0px;
	}
	.left-0{
		left: 0px;
	}
`;

export const Instagram = styled.section`
	div.bg-instagram{
		background: url(${require('~/assets/img/background/bg-instagram.jpg')}) center top no-repeat;
	}
	div.content-pictures-instagram{
		margin-top: -1.5rem;
		div.content-pictures{
			width: 16.66%;
			@media (max-width: 992px){
				width: 33.33%;
			}
			@media (max-width: 576px){
				width: 50%;
			}
		}
	}
	@media (max-width: 768px){
		h2{
			font-size: 60px;
		}
	}
`;

export const Footer = styled.footer`
	height: 680px;
	background: url(${require('~/assets/img/background/bg-footer.jpg')}) center top no-repeat;
	@media (max-width: 992px){
		height: auto;
		a.email{
			font-size: 16px;
			line-height: 16px;
		}
	}
`;

export default () => null;