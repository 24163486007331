import React from 'react';

import axios from 'axios';

import {Instagram} from '../style';

import ReactWOW from 'react-wow';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {faInstagram} from '@fortawesome/free-brands-svg-icons';

export default class componentInstagram extends React.Component{

	state = {
		instagram: {}
	}

	async componentDidMount(){

		const instagram = await axios.get(`https://api.instagram.com/v1/users/self/media/recent/?access_token=3225360069.1677ed0.b1b5e32000f34c458ed6c0d5ded526dc&count=7`);

		await this.setState({
			instagram: instagram.data.data
		});

	}

	render(){

		return(

			<Instagram className="instagram d-flex justify-content-center align-items-center flex-column position-relative z-index-2">

				<div className="bg-instagram w-100 pt-5 pb-5 d-flex justify-content-center align-items-center">

					<ReactWOW animation="fadeIn" duration="2s">

						<h2 className="RobinsonPress pb-4 text-uppercase font-80 lh-70 text-center text-secondary font-normal 
						d-flex justify-content-center align-items-center flex-column">

							<span>Me siga no Instagram</span>

							<span className="d-flex justify-content-center align-items-center">

								<FontAwesomeIcon icon={faInstagram} className="mr-3 text-light font-50" />

								<span className="BrandonGrotesqueBlack font-24 lh-24 text-light">

									{this.props.user}

								</span>

							</span>

						</h2>

					</ReactWOW>

				</div>

				{this.state.instagram && (

					<div className="content-pictures-instagram w-100 d-flex justify-content-between align-items-start flex-wrap">

						{Object.values(this.state.instagram).map((item, index) => (

							<ReactWOW animation="fadeInUp" key={index} delay={`0.${index + 1}s`} duration="2s">

								<div className="content-pictures">

									<a href={item.link} title="Confira" target="_blank" rel="noopener noreferrer">

										<img src={item.images.thumbnail.url} alt="" title="" width="640" 
										height="640" className={`w-100 img-fluid${index % 2 !== 0 ? ' mt-lg-6' : ''}`} />

									</a>

								</div>

							</ReactWOW>

						))}

					</div>

				)}

			</Instagram>

		)

	}

};