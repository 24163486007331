import React from 'react';

import {Carousel} from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import ReactWOW from 'react-wow';

import {Events} from '../style';

const componentEvents = props => {

	console.log(props);

	return(

		<Events className="events w-100 position-relative z-index-3 d-flex justify-content-center align-items-center flex-column flex-xl-row">

			<ReactWOW animation="fadeInUp" duration="2s">

				<h2 className="RobinsonPress text-primary font-normal text-uppercase d-flex justify-content-start align-items-start flex-lg-column mr-lg-5 mb-5 mb-xl-0">

					<span className="mb-lg-2 mr-2 mr-lg-0">Agenda</span>

					<span className="d-flex justify-content-start align-items-start">

						<span className="font-80 lh-70 mr-2">de</span>

						<span>Shows</span>

					</span>

				</h2>

			</ReactWOW>

			{props && (

				<Carousel classNameName="w-100 content-carousel" showThumbs={false} showStatus={false} 
				showArrows={false} showIndicators={true}>

					{Object.values(props).map((event, index) => (

						<article key={index} className="w-100 d-flex justify-content-center align-items-center">

							<div className="d-flex justify-content-center align-items-center align-items-xl-start flex-column">

								<h3 className="sr-only">

									{event.title}

								</h3>

								<strong className="BrandonGrotesqueBlack text-primary font-70 lh-70 mb-3 text-uppercase text-uppercase">

									{event.day} {event.monthAb}

								</strong>

								<div className="d-flex justify-content-center align-items-center justify-content-xl-start align-items-xl-start text-center text-xl-left">

									<i className="fas fa-map-marker-alt text-dark font-50 mr-3 d-none d-xl-inline-block"></i>

									<div className="d-flex justify-content-center align-items-center justify-content-xl-start align-items-xl-start flex-column BrandonGrotesqueBlack text-dark font-24 lh-28">

										<span>

											{`${event.program_name ? event.program_name : event.city + ' / ' + event.uf}`}

										</span>

										<span>

											{event.local_name}

										</span>

									</div>

								</div>

							</div>

						</article>

					))}

				</Carousel>

			)}

		</Events>

	)

};

export default componentEvents;