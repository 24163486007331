import React from 'react';

import ReactWOW from 'react-wow';

import {Parallax} from 'react-parallax';

import {Footer} from '../style';

const componentFooter = props => (

	//const phone_split = props.phone.split(' ', -1);

	//const cell_phone_split = props.cell_phone.split(' ', -1);

	//return(

		<React.Fragment>

			<Parallax className="d-none d-lg-flex" bgImage={require('~/assets/img/background/parallax.jpg')} bgImageAlt="Diogo Silva" strength={500}>

				<div style={{height: '500px'}} />

			</Parallax>

			<Footer id="footer" className="cover pt-5 pb-5">

				<div className="container h-100 d-flex justify-content-between align-items-center flex-column">

					<ReactWOW animation="fadeIn" duration="2s">

						<strong className="RobinsonPress text-dark font-100 lh-100 font-normal mb-5 mb-lg-0">

							Contato

						</strong>

					</ReactWOW>


					<div className="d-flex justify-content-center align-items-center flex-column flex-lg-row">

						<ReactWOW animation="fadeIn" duration="2s">

							<img src={require('~/assets/img/icons/proart.png')} alt="" title="" width="" height="" className="mr-lg-4 mb-5 mb-lg-0" />

						</ReactWOW>

						<div className="d-flex justify-content-center align-items-center justify-content-lg-start align-items-lg-start flex-column ml-lg-4 mb-5 mb-lg-0">

							{props.phone && (

								<ReactWOW animation="fadeInUp" duration="2s">

									<a href={`tel:55${props.phoneDdd}${props.phone.split(' ').join('').replace(/[+()-]/g, '')}`} title="Entrar em contato"
									className="text-dark GothamBlack d-flex justify-content-start align-items-end text-none mb-4">

										<img src={require('~/assets/img/icons/phone.png')} alt="" title="" width="29" 
										height="29" className="d-none d-lg-inline-block mr-3" />

										<span className="font-18 lh-18 mr-2 mr-lg-3">

											{props.phoneDdd}

										</span>

										<span className="font-34 lh-30">

											{props.phone}

										</span>

									</a>

								</ReactWOW>

							)}


							{props.cellPhone && (

								<ReactWOW animation="fadeInUp" duration="2s" delay="0.1s">

									<a href={`https://api.whatsapp.com/send?1=pt_BR&phone=55${props.cellPhoneDdd}${props.cellPhone.split(' ').join('').replace(/[+()-]/g, '')}`}
									title="Direto no WhatsApp" className="text-dark GothamBlack d-flex justify-content-start align-items-end text-none mb-4" 
									target="_blank" rel="noopener noreferrer">

										<img src={require('~/assets/img/icons/whatsapp.png')} alt="" title="" width="29" 
										height="29" className="d-none d-lg-inline-block mr-3" />

										<span className="font-18 lh-18 mr-2 mr-lg-3">

											{props.cellPhoneDdd}

										</span>

										<span className="font-34 lh-30">

											{props.cellPhone}

										</span>

									</a>

								</ReactWOW>

							)}


							{props.email && (

								<ReactWOW animation="fadeInUp" duration="2s" delay="0.2s">

									<a href={`mailto:${props.email}`} title="Enviar E-mail" 
									className="email text-dark GothamBlack font-20 lh-20 text-lowercase d-flex justify-content-start align-items-end text-none mb-4">

										<img src={require('~/assets/img/icons/email.png')} alt="" title="" width="26" 
										height="19" className="d-none d-lg-inline-block mr-2" />

										<span>

											{props.email}

										</span>

									</a>

								</ReactWOW>

							)}


							{props.address && (

								<ReactWOW animation="fadeInUp" duration="2s" delay="0.3s">

									<a href={`mailto:${props.address}`} title="Enviar E-mail" 
									className="email text-dark GothamBlack font-20 lh-20 text-lowercase d-flex justify-content-start align-items-end text-none">

										<img src={require('~/assets/img/icons/email.png')} alt="" title="" width="26" 
										height="19" className="d-none d-lg-inline-block mr-2" />

										<span>

											{props.address}

										</span>

									</a>

								</ReactWOW>

							)}

						</div>

					</div>


					{props.link_contratantes && (

						<a href={props.link_contratantes} title="Acesso exclusivo para contratantes"
						className="mb-5 mb-lg-0" target="_blank" rel="noopener noreferrer">

							<img src={require('~/assets/img/icons/contratantes.png')} alt="" title="" width="217" height="95" className="img-fluid" />

						</a>

					)}


					<a href="https://www.icomp.com.br" title="Desenvolvido por Agência ICOMP" target="_blank" rel="noopener noreferrer">

						<img src={require('~/assets/img/icons/icomp.png')} alt="Desenvolvido por Agência ICOMP" 
						title="Desenvolvido por Agência ICOMP" width="253" height="36" className="img-fluid" />

					</a>

				</div>

			</Footer>

		</React.Fragment>

	//)

);

export default componentFooter;